.app {
  background-color: #fff;
}

.app__container {
  min-width: 320px;
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: "Inter", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}