.section {
  background-color: #fafafa;
  padding: 110px 70px;
}

.section__header {
  font-size: 22px;
  font-weight: 400;
  line-height: 26.63px;
  margin: 0;
  padding: 0 0 23px 0;
  border-bottom: 1px solid #000;
  letter-spacing: -0.04em;
}

.section_dark {
  background-color: #f5f5f5;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 800px) {
  .section {
    padding: 90px 50px;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding: 70px 18px 94px 18px;
  }

  .section__header {
    padding: 0 0 28px;
    font-size: 18px;
    line-height: 22px;
  }

  .section_dark {
    background-color: unset;
  }
}

@media screen and (max-width: 320px) {
  .section_dark {
    padding-top: 48px;
    padding-bottom: 70px;
  }

  .section_dark {
    background-color: #fafafa;
  }
}