.logo-link {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
  text-decoration: none;
  width: 38px;
  height: 38px;
  display: block;
  background: url(../../images/logo.svg);
  background-size: cover;
}

.logo-link:hover {
  transition: .3s;
  opacity: .7;
}