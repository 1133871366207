.menu {
  box-sizing: border-box;
  padding: 151px 0 0;
  margin: 0 auto;
  width: 308px;
}

.menu__list {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu__list-item {
  box-sizing: border-box;
  background-color: #e8e8e8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 36px;
}

.menu__link {
  color: #000;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 822px) {
  .menu {
    padding: 175px 0 0;
  }
}

@media screen and (max-width: 713px) {
  .menu {
    padding: 175px 0 17px;
  }
}

@media screen and (max-width: 360px) {
  .menu {
    width: 295px;
  }
}

@media screen and (max-width: 330px) {
  .menu {
    padding: 153px 0 17px;
    width: 258px;
  }

  .menu__list-item {
    border-radius: 6px;
    width: 82px;
    height: 26px;
  }

  .menu__list {
    font-size: 10px;
  }
}