.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 0 70px 20px;
  box-sizing: border-box;
}

.footer__title {
  padding: 80px 0 20px;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  color: #a0a0a0;
}

.footer__bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 0 0;
  align-items: initial;
}

.footer__copyright {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  color: #000;
  margin: 0;
}

.footer__links {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.footer__links-item {
  margin-right: 20px;
}

.footer__links-item:last-of-type {
  margin-right: 0;
}

.footer__link {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.73px;
  text-decoration: none;
  color: #000;
  transition: opacity .3s;
}

.footer__link:hover {
  opacity: .7;
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 0 30px 20px;
  }

  .footer__title {
    padding-top: 62px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 0 10px 10px;
  }

  .footer__title {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__bottom-row {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
  }

  .footer__links-item {
    margin: 0 0 12px 0;
  }

  .footer__links-item:last-of-type {
    margin: 0 0 30px 0;
  }

  .footer__link {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__copyright {
    font-size: 12px;
    line-height: 15px;
    color: #a0a0a0;
  }
}

@media screen and (max-width: 320px) {
  .footer__title {
    padding-top: 60px;
  }

  .footer__bottom-row {
    padding-top: 27px;
  }

  .footer__links-item {
    margin-bottom: 9px;
  }

  .footer__links-item:last-of-type {
    margin: 0 0 30px 0;
  }
}