.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #fafafa;
}

.profile__form {
  display: flex;
  flex-direction: column;
  min-width: 410px;
  justify-content: space-between;
  max-width: 410px;
}

.profile__form-message {
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin-top: 30px;
}

.profile__form-message_succeed {
  color: #000;
}

.profile__form-message_error {
  color: #ee3465;
}

.profile__input-label {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 11px;
  line-height: 13.31px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.profile__input-label:last-of-type {
  border-bottom: none;
  padding-top: 17px;
  padding-bottom: 0;
}

.profile__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  margin: 0;
  padding: 74px 0 123px;
}

.profile__input {
  background-color: transparent;
  border: none;
  font-weight: 400;
  font-size: 11px;
  line-height: 13.31px;
  text-align: right;
  padding: 0;
  height: 14px;
  width: 100%;
  max-width: 300px;
}

.profile__input::placeholder {
  opacity: .4;
}

.profile__input:focus {
  outline: none;
  text-decoration: none;
  border: 1px solid #000;
  border-radius: 3px;
}

.profile__form-submit {
  margin-top: 220px;
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  cursor: pointer;
  max-width: 96px;
  align-self: center;
  padding: 0;
}

.profile__form-submit:hover {
  transition: .3s;
  opacity: .7;
}

.profile__form-submit_disabled {
  opacity: inherit;
  cursor: unset;
}

.profile__form-submit_disabled:hover {
  transition: .3s;
  opacity: 1;
}

.profile__signout-link {
  max-width: 122px;
  align-self: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.73px;
  color: #ee3465;
  text-decoration: none;
  margin-top: 16px;
}

.profile__signout-link:hover {
  transition: .3s;
  opacity: .7;
}

@media screen and (max-width: 800px) {
  .profile__title {
    padding: 236px 0 96px;
  }

  .profile__form-submit {
    margin-top: 210px;
  }
}

@media screen and (max-width: 500px) {
  .profile__title {
    padding: 70px 0 80px;
  }

  .profile__form {
    min-width: 260px;
  }

  .profile__form-submit {
    margin-top: 380px;
    font-size: 12px;
    line-height: 15px;
  }

  .profile__signout-link {
    font-size: 12px;
    line-height: 15px;
  }
}