.movies-list {
  background-color: #fafafa;
  padding: 80px 70px 0;
  display: flex;
  flex-direction: column;
}

.movies-list__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px 28px;
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px;
}

.movies-list__add-btn {
  margin: 50px 0 80px;
  align-self: center;
  width: 100%;
  height: 36px;
  background-color: #f4f4f4;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}

.movies-list__add-btn:hover {
  transition: .8s;
  color: #fff;
  background-color: #ee3465;
}

.movies-list__add-btn_disabled {
  opacity: 0;
  cursor: auto;
}

.hidden {
  display: none;
}

.movies-list__no-saved-films {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.movies-list__not-found {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.movies-list__error {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .movies-list {
    padding: 80px 30px 0;
  }

  .movies-list__items {
    gap: 35px 24px;
    margin: 0 0 30px;
  }

  .movies-list__add-btn {
    margin: 50px 0 98px;
  }
}

@media screen and (max-width: 700px) {
  .movies-list {
    padding: 40px 10px 0;
  }

  .movies-list__items {
    gap: 16px;
  }

  .movies-list__add-btn {
    width: 240px;
    height: 36px;
  }
}

@media screen and (max-width: 320px) {
  .movies-list {
    padding-top: 50px;
  }
}