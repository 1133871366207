.header_theme_white {
  background-color: #FAFAFA;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 70px;
}

.header__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header__links-item {
  margin-right: 30px;
}

.header__links-item_logo {
  margin-right: 44px;
}

.header__links-item_left {
  margin-right: 16px;
}

.header__links-item:last-of-type {
  margin-right: 0;
}

.header__link {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  line-height: 16px;
  text-decoration: none;
}

.header__link:hover {
  transition: .3s;
  opacity: .7;
}

.header__link_type_signin {
  display: block;
  padding: 8px 20px;
  background-color: #3456F3;
  color: #fff;
  border-radius: 3px;
}

.header__link_type_films {
  font-size: 14px;
  line-height: 18px;
}

.header__link_type_savedfilms {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-right: 40px;
}

.header__link_type_account {
  font-size: 13px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 3px 3px 3px 7px;
  box-shadow: 0px 0px 19px 0px rgba(34, 60, 80, .2);
}

.header__link_type_account::after {
  content: "";
  background-image: url("../../images/accountIcon.svg");
  background-color: #f4f4f4;
  background-position: center;
  background-size: 10.4px 10.7px;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  float: right;
}

.header__menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, .3);
  z-index: 1;
  visibility: hidden;
}

.header__menu-wrapper_visible {
  visibility: visible;
}

.header__menu-button {
  width: 44px;
  height: 44px;
  background-image: url("../../images/burgerMenuIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin: 0;
  background-color: inherit;
  border: none;
  cursor: pointer;
  display: none;
}

.header__menu-button:hover {
  transition: .3s;
  opacity: .7;
}

.header__menu-links-wrapper {
  background-color: #fafafa;
  transform: translateX(100%);
  transition: .3s;
  visibility: hidden;
  padding: 160px 90px 92px;
  width: 44%;
}

.visible-animation {
  transition: .3s;
  visibility: visible;
  transform: translateX(0);
}

.header__menu-button-close {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: inherit;
  background-image: url("../../images/closeBurgerMenuIcon.svg");
  border: none;
  position: absolute;
  top: 23px;
  right: 23px;
  padding: 0;
  cursor: pointer;
}

.header__menu-button-close:hover {
  transition: .3s;
  opacity: .7;
}

.header__menu-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  list-style-type: none;
  height: 100%;
  padding: 0;
}

.header__menu-links-item {
  margin-bottom: 28px;
}

.header__menu-links-item:last-of-type {
  margin: auto 0 0;
}

.header__menu-link {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  text-decoration: none;
  padding-bottom: 4px;
}

.header__menu-link:hover {
  transition: .3s;
  opacity: .7;
}

.header__menu-link_active {
  border-bottom: 2px solid #000;
}

.header__menu-link_type_account {
  font-size: 13px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 3px 3px 3px 7px;
  box-shadow: 0px 0px 19px 0px rgba(34, 60, 80, .2);
}

.header__menu-link_type_account::after {
  content: "";
  background-image: url("../../images/accountIcon.svg");
  background-color: #f4f4f4;
  background-position: center;
  background-size: 10.4px 10.7px;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  float: right;
}

@media screen and (max-width: 840px) {
  .header__link_type_account {
    display: none;
  }

  .header__link_type_films {
    display: none;
  }

  .header__link_type_savedfilms {
    display: none;
  }

  .header__menu-button {
    display: block;
    width: 38px;
    height: 38px;
  }

  .header__menu-links-item:last-of-type {
    margin: 593px 0 0;
  }

  .header__nav {
    padding: 18px 30px;
  }
}

@media screen and (max-width: 700px) {
  .header__menu-links-wrapper {
    width: 100%;
    padding: 100px 10px 44px;
  }
}

@media screen and (max-width: 320px) {
  .header__nav {
    padding: 18px 14px;
  }

  .header__menu-button {
    width: 38px;
    height: 38px;
  }

  .header__menu-button-close {
    top: 14px;
    right: 14px;
  }

  .header__links-item {
    margin-right: 15px;
  }
  
  .header__menu-links-wrapper {
    padding-top: 144px;
  }

  .header__menu-links-item {
    margin-bottom: 25px;
  }

  .header__menu-links-item:last-of-type {
    margin-top: 418px;
  }

  .header__link {
    font-size: 10px;
  }

  .header__link_type_signin {
    display: block;
    padding: 4px 12px;
    background-color: #3456F3;
    color: #fff;
    border-radius: 3px;
  }
}