.not-found-error {
  min-height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found-error__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 185px;
}

.not-found-error__title {
  font-size: 140px;
  line-height: 170px;
  margin: 0 0 5px;
  font-weight: 400;
}

.not-found-error__subtitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}

.not-found-error__link {
  color: #3456F3;
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
}

.not-found-error__link:hover {
  transition: .3s;
  opacity: .7;
}

@media screen and (max-width: 321px) {
  .not-found-error__title {
    font-size: 80px;
    line-height: 97px;
    margin: 0 0 10px;
  }

  .not-found-error__subtitle {
    font-size: 12px;
    line-height: 15px;
  }

  .not-found-error__wrapper {
    margin-bottom: 285px;
  }
}