.promo {
  box-sizing: border-box;
  background-color: #fafafa;
  padding-top: 20px;
  margin: 0 auto;
  min-height: 513px;
  width: 100%;
  max-width: 1280px;
}

.promo__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 493px;
}

.promo__title {
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  letter-spacing: -2px;
  padding: 160px 70px 0;
  margin: 0 auto;
  max-width: 730px;
}

@media screen and (max-width: 822px) {
  .promo__wrapper {
    width: calc(100vw - 5.5vw);
    min-height: 834px;
  }

  .promo__title {
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    padding: 350px 70px 140px;
    margin: 0;
    letter-spacing: -1.6px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 320px) {
  .promo {
    border-radius: 10px;
    padding-top: 14px;
    min-height: 586px;
  }

  .promo__wrapper {
    min-height: 400px;
  }

  .promo__title {
    font-size: 29px;
    line-height: 37px;
    letter-spacing: -1.3px;
    padding: 220px 10px 60px;
  }
}