.about-me__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 66px 0 0 0;
}

.about-me__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
}

.about-me__title {
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin: 0 0 18px 0;
  letter-spacing: -0.04em;
}

.about-me__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 26px 0;
}

.about-me__paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 100px 0;
}

.about-me__networks {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.about-me__networks-item {
  margin-right: 20px;
}

.about-me__networks-item:last-of-type {
  margin-right: 0;
}

.about-me__networks-link {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  transition: opacity .2s;
}

.about-me__networks-link:hover {
  opacity: .7;
}

.about-me__avatar {
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  width: 270px;
  height: 327px;
}

@media screen and (max-width: 1070px) {
  .about-me__info {
    margin-right: 49px;
  }
}

@media screen and (max-width: 800px) {
  .about-me__info {
    max-width: 364px;
  }

  .about-me__title {
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 16px;
  }

  .about-me__subtitle {
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 18px;
  }

  .about-me__paragraph {
    margin: 0 0 87px;
    font-size: 12px;
    line-height: 18px;
  }

  .about-me__avatar {
    width: 255px;
    height: 307px;
  }
}

@media screen and (max-width: 550px) {
  .about-me__wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-me__avatar {
    width: 292px;
    height: 352px;
  }

  .about-me__info {
    margin: 0;
  }

  .about-me__title {
    margin: 40px 0 20px;
    font-size: 30px;
    line-height: 36px;
  }

  .about-me__subtitle {
    font-size: 11px;
    line-height: 16px;
  }

  .about-me__paragraph {
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 320px) {
  .about-me__wrapper {
    padding-top: 60px;
  }
}