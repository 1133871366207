.filtercheckbox {
  display: flex;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}

.filtercheckbox__input {
  display: none;
}

.filtercheckbox__visible-input {
  display: inline-block;
  width: 36px;
  height: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  margin-right: 15px;
  box-shadow: 0px 5px 5px -1px rgba(34, 60, 80, .3);
}

.filtercheckbox__visible-input::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 55%;
  left: 25%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #F5F5F5;
}

.filtercheckbox__input:checked + .filtercheckbox__visible-input::before {
  transition: .3s;
  left: 75%;
  background-color: #2BE080;
}

.filtercheckbox__input:checked + .filtercheckbox__visible-input {
  transition: .3s;
  background-color: #fff;
}

.filtercheckbox__input:not(checked) + .filtercheckbox__visible-input::before {
  transition: .3s;
  left: 30%;
}

.filtercheckbox__input:not(checked) + .filtercheckbox__visible-input {
  transition: .3s;
  background-color: #fff;
}

@media screen and (max-width: 700px) {
  .filtercheckbox {
    position: absolute;
    bottom: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .filtercheckbox {
    font-size: 11px;
  }
}