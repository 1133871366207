.about {
  background-color: #202020;
  color: white;
}

.about__text-columns {
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 110px;
}

.about__text-column {
  max-width: 550px;
}

.about__text-header {
  font-weight: 400;
  font-size: 20px;
  line-height: 24.2px;
  padding-bottom: 26px;
  margin: 0;
  letter-spacing: -0.04em;
}

.about__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.about__progressbar {
  width: 100%;
  height: 36px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: #f2f2f2;
}

.about__progressbar:last-child {
  margin-bottom: 32px;
}

.about__progressbar-backend {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
  background-color: #3456F3;
}

.about__progressbar-backend::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "1 неделя";
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

.about__progressbar-backend::after {
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translate(-50%, -50%);
  width: 80px;
  content: "Back-end";
  color: #a0a0a0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.about__progressbar-frontend {
  display: block;
  position: relative;
  width: 100%;
  height: 36px;
}

.about__progressbar-frontend::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "4 недели";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

.about__progressbar-frontend::after {
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translate(-50%, -50%);
  content: "Front-end";
  color: #a0a0a0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

@media screen and (max-width: 1120px) {
  .about__text-column {
    margin-right: 50px;
  }
}

@media screen and (max-width: 800px) {
  .about__text-columns {
    padding: 70px 0 93px;
  }

  .about__text-column {
    margin-right: 0px;
    width: 320px;
  }

  .about__text-header {
    padding-bottom: 22px;
  }

  .about__text {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 550px) {
  .about__text-columns {
    padding: 60px 0;
    flex-direction: column;
  }

  .about__text-column {
    margin-bottom: 56px;
    margin-right: 0;
    width: 300px;
  }

  .about__text-column:last-of-type {
    margin-bottom: 0;
  }

  .about__text-header {
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 16px;
  }

  .about__text {
    font-size: 11px;
    line-height: 16px;
  }

  .about__progressbar {
    grid-template-columns: 100px 1fr;
  }

  .about__progressbar-backend::before {
    font-size: 11px;
    line-height: 13px;
  }

  .about__progressbar-backend::after {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
  }

  .about__progressbar-frontend::before {
    font-size: 11px;
    line-height: 13px;
  }

  .about__progressbar-frontend::after {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .about__text-column {
    margin-right: 0px;
    width: 100%;
  }

  .about__progressbar-backend::after {
    top: 50px;
  }

  .about__progressbar-frontend::after {
    top: 50px;
  }

  .about__progressbar:last-child {
    margin-bottom: 20px;
  }
}